import React from 'react'
import Link from 'components/Link'
import SDKComponent from 'components/highlighter/Sdk'
const SectionIntegrations = () => (
  <section className="section section__integrations">
    <div className="container">
      <div className="row integrations-container">
        <div className="col-sm-8">
          <img
            src="/assets/images/elements/pentafile-integration.png"
            id="integrations-illustrated"
            className="visible-xs"
          />
          <h2>Implemente desde los lenguajes que trabajas</h2>
          <p className="highlight-p">
            Pentafile tiene múltiples clientes SDK para la integración con tus
            aplicaciones y además puedes conectarte directamente a nuestra API
          </p>
          <p className="highlight-p">
            <Link to="/docs/">Docs →</Link>
          </p>
        </div>
        <div className="col-sm-4 text-right">
          <img
            src="/assets/images/elements/pentafile-integration.png"
            id="integrations-illustrated"
            className="hidden-xs"
          />
        </div>
      </div>
      <div>
        <br />
        <SDKComponent />
      </div>
    </div>
  </section>
)
export default SectionIntegrations
