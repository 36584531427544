import React from 'react'
import Layout from 'components/Layout'
import Link from 'components/Link'
import SectionIntegrations from 'components/SectionIntegrations'
import SectionGetStarted from 'components/SectionGetStarted'

class Homepage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      controls: null,
    }

    this.addControls = this.addControls.bind(this)
    this.removeControls = this.removeControls.bind(this)
  }

  addControls() {
    this.setState({ controls: 'controls' })
  }

  removeControls() {
    this.setState({ controls: null })
  }

  render() {
    return (
      <Layout
        title="Pentafile simplifica la gestión de tus archivos"
        description="Pentafile es una forma inteligente para gestionar grandes cantidades de archivos en tu organización, configura y usa la mejor alternativa"
        location={this.props.location}
      >
        <section className="section section--hero">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h1 className="mb-xs">Simplifica el acceso a tus archivos</h1>
                <h3 className="font-light">
                  Configure y carge archivos desde multiples clientes y
                  aplicaciones.
                </h3>
                <Link
                  data-track
                  data-track-location="hero"
                  track-event="Clicked Get Started"
                  to="https://console.pentafile.com"
                  className="btn btn-primary btn-lg"
                >
                  Empezar
                </Link>
              </div>
              <div className="col-md-7 hidden-xs hidden-sm">
                <div className="browser-container hidden-xs">
                  <img src="/assets/images/elements/pentafile-header.png" />
                  <img src="/assets/images/elements/pentafile-architecture.png" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section section__more-features">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xs-12 p-0 flex-content-center flex">
                <h2>Pentafile te provee una colección de funcionalidades</h2>
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xs-12 p-0 flex-content-center">
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="card-content">
                    <img
                      className="card-img-top"
                      src="/assets/images/elements/pentafile-icon-upload.png"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Carga de archivos</h5>
                      <p className="card-text">
                        Carga archivos de forma simple y con múltiples opciones
                        de configuración e integración, SDK y componentes UI
                        para integrar en tus aplicaciones.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="card-content">
                    <img
                      className="card-img-top"
                      src="/assets/images/elements/pentafile-icon-security.png"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      Seguridad
                      </h5>
                      <p className="card-text">
                        Administra archivos públicos y privados en tus
                        aplicaciones, los accesos privados se realizan mediante
                        mecanismos de URL firmadas.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="card-content">
                    <img
                      className="card-img-top"
                      src="/assets/images/elements/pentafile-icon-tranformation.png"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      Transformación
                      </h5>
                      <p className="card-text">
                        Disponemos de herramientas para manipulación de imágenes
                        y utilizar en tus aplicaciones (Crop, Rotate,
                        Optimización, Reducción, Thumbnail y mucho más).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12 col-xs-12 p-0 flex-content-center">
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="card-content">
                    <img
                      className="card-img-top"
                      src="/assets/images/elements/pentafile-icon-cdn.png"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      Entrega de contenido
                      </h5>
                      <p className="card-text">
                        Pentafile usa una CDN para garantizar el acceso global y
                        confiable de los archivos que administra sus
                        aplicaciones para reducir el tiempo de respuesta.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="card-content">
                    <img
                      className="card-img-top"
                      src="/assets/images/elements/pentafile-icon-preview.png"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      Visualización
                      </h5>
                      <p className="card-text">
                        Visualiza tus archivos en linea, sin necesidad de
                        descargarlo, es configurable para que puedas integrarlo
                        en simples pasos.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="card-content">
                    <img
                      className="card-img-top"
                      src="/assets/images/elements/pentafile-icon-backup.png"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      Backups
                      </h5>
                      <p className="card-text">
                        Resguardo para todo tipo de archivos, aplicaciones, base
                        de datos, backups automáticos y mucho más.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12 col-xs-12 p-0 flex-content-center">
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="card-content">
                    <img
                      className="card-img-top"
                      src="/assets/images/elements/pentafile-icon-admin.png"
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                      Administración
                      </h5>
                      <p className="card-text">
                        Administra tus archivos de forma centralizada y realiza
                        el seguimiento y configuración, todo desde un portal de
                        administración
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-center">
                <br />
                <Link
                  data-track
                  data-track-location="features"
                  track-event="Clicked All Pentafile Features"
                  to="/product"
                  className="btn btn-secondary"
                >
                  Ver mas
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="section section__feature section__feature--query">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <img
                  src="/assets/images/elements/pentafile-feature.png"
                  id="write-query-illustrated"
                  className="visible-xs"
                />

                <h1>Integra facilmente a tus aplicaciones</h1>

                <p className="highlight-p">
                  Fué diseñado para hacer más simple el acceso a tus archivos
                </p>
                <ul className="highlight-ul">
                  <li>Carga rápida de archivos</li>
                  <li>Integración fácil mediante RESTAPI</li>
                  <li>Multiples Helpers SDK</li>
                  <li>Cada vez más componentes</li>
                </ul>
              </div>
              <div className="col-sm-6 hidden-xs">
                <img
                  src="/assets/images/elements/pentafile-feature.png"
                  id="write-query-illustrated"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section section__feature section__feature--visualize">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <img
                  src="/assets/images/elements/visualize-data.png"
                  id="visualize-illustrated"
                />
              </div>
              <div className="col-sm-6">
                <h1>Administre tus archivos desde un solo lugar</h1>

                <p className="highlight-p">
                  Único punto de administración, monitoreo y estadísticas a tu disposición
                </p>

                <ul className="highlight-ul">
                  <li>Estadísticas</li>
                  <li>Trazabilidad</li>
                  <li>Notificación</li>
                  <li>Seguridad</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <SectionIntegrations />
        <SectionGetStarted />
      </Layout>
    )
  }
}

export default Homepage
