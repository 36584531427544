import React from 'react'

import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'

const lang_javascript_txt = `window.pentafile = new pentafile("app key");

pentafile.uploadFileUI({

  start: function (file) {
    console.log('start', file);
  },

  success: function (record) {
    console.log("success record ",record);
  },
  
  error: function (error) {
    console.log('error', error);
  }
});
`
const lang_javascript_txt_header = `<!-- put in your header -->
<script src="https://static.pentafilestore.com/pentafile.1.0.0.min.js" />`
const lang_java_txt = `import com.pentafile.sdk.PentafileAPI;
import com.pentafile.sdk.bean.ObjectFile;
import com.pentafile.sdk.exception.PentafileException;

public class MainSampleIntegration  {

    public static PentafileAPI API = null;

    static {
         API = PentafileFactory.newInstance("AppKey");
    }

    public static void main(String[] args) throws PentafileException {

      File archivo = new File("G:\\storage\\pentafile\\sanrafael.jpg");
      ObjectFile file = API.uploadFile(archivo.getName(), new FileInputStream(archivo));

      System.out.println("key :" + file.getKey());
      System.out.println("type : " + file.getType());
      System.out.println("size : " + file.getSize());
      System.out.println("id: " + file.getId());
      System.out.println("url : " + file.getUrl());
    }
}`
const lang_java_txt_header = `<!-- put in your pom.xml -->
<repositories>
        <repository>
            <id>pentafile-release</id>
            <url>http://developer.sotechcloud.com/archiva/repository/pentafile-release/</url>
        </repository>
</repositories>
<!-- put in your pom.xml -->
<dependency>
  <groupId>com.pentafile</groupId>
  <artifactId>pentafile-jdk-java</artifactId>
  <version>1.0.0</version>
</dependency>
`
const lang_php_txt = `<?php
require './Pentafile.php';

$PentafileAPI = new Pentafile("appkey");

$file_path = dirname(__FILE__) . '/bitnami.css';

$file_content = file_get_contents($file_path);

uploadFile($PentafileAPI, basename($file_path), $file_content);


function uploadFile($API, $filename, $content_file) {
    try {
        $ObjectFile = $API->uploadFile($filename, $content_file);
        echo $ObjectFile->getKey() . "<br>";
        echo $ObjectFile->getFilename() . "<br>";
        echo $ObjectFile->getSize() . "<br>";
        echo $ObjectFile->getType() . "<br>";
        echo $ObjectFile->getUrl() . "<br>";
    } catch (Exception $ex) {
        echo "Error: " . $ex;
    }
}
?>`

export default class styleLang extends React.Component {
  render() {
    let data = ''
    let header = ''
    if (this.props.lang === 'javascript') {
      header = lang_javascript_txt_header
      data = lang_javascript_txt
    }
    if (this.props.lang === 'java') {
      header = lang_java_txt_header
      data = lang_java_txt
    }
    if (this.props.lang === 'php') {
      data = lang_php_txt
    }
    return (
      <div>
        <SyntaxHighlighter
          showLineNumbers={true}
          language={this.props.lang}
          style={docco}
        >
          {data}
        </SyntaxHighlighter>
        {header != '' && (
          <SyntaxHighlighter
            showLineNumbers={false}
            language={'xml'}
            style={docco}
          >
            {header}
          </SyntaxHighlighter>
        )}
      </div>
    )
  }
}
