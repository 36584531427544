import React from "react";
import Link from 'components/Link'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import StyleLang from "./Lang";
import "./style.scss";
export default class SDKComponent extends React.Component {
  constructor(props) {
    super(props);

    this.characters = {
      javascript: {
        img:
          "https://www.shareicon.net/data/256x256/2016/07/06/106573_software_512x512.png",
        color: "#e6e5e5",
        text: "Javascript",
        linkDoc:"https://pentafile.gitbook.io/docs/clientes-sdk#sdk-javascript"
      },
      java: {
        img:
          "https://cdn6.aptoide.com/imgs/f/e/a/fea4ceefa5da3570e651f2c9a52603c8_icon.png?w=256",
        color: "#e6e5e5",
        text: "Java",
        linkDoc:"https://pentafile.gitbook.io/docs/clientes-sdk#sdk-java"
      },
      php: {
        img: "https://cdn.iconscout.com/icon/free/png-256/php-99-1175127.png",
        color: "#e6e5e5",
        text: "php",
        linkDoc:"https://pentafile.gitbook.io/docs/clientes-sdk#sdk-php"
      }
    };

    this.state = {
      javascript: true,
      java: true,
      php: true
    };
  }

  render() {
    const tabs = [];
    const tabPanels = [];
    Object.keys(this.characters).forEach(name => {
      if (!this.state[name]) return;
      const { img, color: backgroundColor, text: color,linkDoc } = this.characters[name];
      tabs.push(
        <Tab style={{ backgroundColor }} className="avengers-tab" key={name}>
          <img src={img} alt={name} height="32" width="32" />
        </Tab>
      );
      tabPanels.push(
        <TabPanel
          style={{ backgroundColor, color }}
          className="avengers-tab-panel"
          key={name}
        >
          <StyleLang lang={name} />
          <div>
            <Link to={linkDoc} target="_blank">Ir a GitBook <i className="fa fa-book" aria-hidden="true" /> para documentación e instrucciones</Link>
          </div>
        </TabPanel>
      );
    });

    return (
      <div>
        <Tabs
          selectedTabClassName="avengers-tab--selected"
          selectedTabPanelClassName="avengers-tab-panel--selected"
        >
          <TabList className="avengers-tab-list">{tabs}</TabList>
          {tabPanels}
        </Tabs>
      </div>
    );
  }
}
